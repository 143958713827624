import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './routes'
import store from './vuex/store'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import Xmwtable from 'vue3-xmw-table'



const app = createApp(App)
//挂载vue3-xmw-table
app.use(Xmwtable)
//挂载element
app.use(ElementPlus)
//挂载路由
app.use(router)
//挂载vuex
app.use(store)
//使用图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.mount('#app')
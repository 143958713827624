import {createRouter,createWebHashHistory} from 'vue-router'
import Login from "./components/Login.vue";
import Index from "./views/index.vue"
// import LoveFlower from "./views/LoveFlower.vue" 
// import BirthdayFlower from "./views/BirthdayFlower.vue" 
// import FriendFlower from "./views/FriendFlower.vue" 
// import WeddingFlower from "./views/WeddingFlower.vue" 
// import FlowerDetail from "./views/FlowerDetail.vue"  
// import FlowerPay from "./views/FlowerPay.vue"  
// import Personcenter from "./views/PersonCenter.vue"  
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes: [
    { path: '/', component: Login },
    { 
      path: '/Index', 
      component: Index ,
      children:[
        {
          path: "/home",
          name: "home",
          meta: {
            title: '首页',
            icon: 'House'
          },
          /** 在src/views 文件下创建home文件 */
          component: () => import("@/views/home/home.vue"),
        },
        {
          path: "/userManagement",
          name: "userManagement",
          meta: {
            title: '用户管理',
            icon: 'Avatar'
          },
          /** 在src/views 文件下创建userManagement文件 */
          component: () => import("@/views/userManagement/userManagement.vue"),
          children: [
            {
              path: "/productAdministratorA",
              name: "productAdministratorA",
              meta: {
                title: 'Archermind_Name_List',
                icon: 'TakeawayBox'
              },
              /** 在src/views 文件下创建productAdministrator文件 */
              component: () => import("@/views/userManagement/productAdministratorA.vue"),
            },
            {
              path: "/productAdministratorB",
              name: "productAdministratorB",
              meta: {
                title: 'Hiring_Status',
                icon: 'TakeawayBox'
              },
              /** 在src/views 文件下创建productAdministrator文件 */
              component: () => import("@/views/userManagement/productAdministratorB.vue"),
            }
				]
        },
      ]
    }
  ],
})
export default router
<template>
    <div class="login-back">
      <div class="login-container" >
        <h2>人员信息管理系统</h2>
        <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules" label-position="top">
          <el-form-item prop="UserName" label="用户名">
            <el-input v-model="loginForm.UserName" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="Password" label="密码">
            <el-input type="password" v-model="loginForm.Password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button  type="primary" @click="login(loginFormRef)">登录</el-button>
            <el-button  @click="register">注册</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </template>
   
  <script lang="ts" setup>
  import {reactive, ref} from 'vue';
  
  import { useRouter } from 'vue-router';
  import axios from "axios";
  import {ElMessage} from "element-plus";
  import { LoginUsers,GetValidateCodeImages } from "../http/index";
  import { useStore } from "vuex";
  import type { FormInstance,FormRules } from 'element-plus'
   
  
  const router = useRouter();
  const store = useStore()

  interface RuleForm {
  NickName: string
  UserName: string
  Password: string
  ValidateKey: string
  ValidateCode: string
}
  const loginForm = reactive<RuleForm>({
    NickName:"",
    UserName:"",
    Password:"",
    ValidateKey:"1",
    ValidateCode:"2"
  });

const loginRules = reactive<FormRules<RuleForm>>({
    UserName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
    Password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
})

  const loginFormRef = ref(null);

  const login = async (formEl: FormInstance | undefined)  => {
    if (!formEl) return
    await formEl.validate((valid, fields) => {
    if (valid) {
       // 在这里进行登录逻辑处理
        // 用户登录
        userLogin()
    }
  })
  };
   
  const userLogin = async() => {
    console.log(loginForm)
    try {
      var result = (await LoginUsers(loginForm)).data
      console.log(result)
      if(result.isSuccess==true){
        clearUser()
        console.log(result.result.nickName)
        store.commit("SettingNickName",result.result.nickName)
        //store.commit("CloseLogin")
        ElMessage({
          message: result.message,
          type: 'success',
        })
        router.push('/Index')
      }
      else{
        ElMessage.error(result.message)
      } 
    } catch (error) {
      ElMessage.error(error.message)
    }
    
    
    
  }
  
  const register = () => {
      // 用户登录
      router.push('/sign') 
  };


  const clearUser =() =>{
    loginForm.NickName = ""
    loginForm.UserName = ""
    loginForm.Password = ""
    loginForm.ValidateKey = "1"
    loginForm.ValidateCode = "2"
}
  
  </script>
   
  <style scoped>
  .l-title{
    margin-top: 200px;
    font-size: 30px;
    text-align: center;
    justify-content: center;
  }
  .login-back {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../assets/img/login.jpg");
  }
  .login-container {
    position: absolute;
    top: 30%;
    left: 70%;
    max-width: 300px;
    min-width: 300px;
    height: 400px;
    padding: 40px;
    /*margin-top: 200px;*/
    /*margin-left: 1300px;*/
    /*border: 1px solid #caebffa1;*/
    border-radius: 4px;
    background-color: #caebff3b;
    opacity: 0.9; /* 设置透明度为 50% */
  }
  .login-container h2 {
    text-align: center;
    margin-top:50px ;
    margin-bottom: 50px;
  }
  .el-form-item .el-button {
    margin-left: 50px; /* 设置按钮之间的间距 */
    margin-right: 30px;
  }
  .el-radio-group .el-radio {
    margin-left: 30px;
  }
  .login-c{
    margin: 200px 0 0 600px;
    padding: 0;
    width: 382px;
    height: 580px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #cccccc;
    opacity: 0.9; /* 设置透明度为 50% */
  }
   
  </style>
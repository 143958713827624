import {createStore} from 'vuex'
const store = createStore({
    state(){
        return {
            isShowLogin:false,
            isShowRegister: false,
            NickName:localStorage["NickName"]//昵称，登陆后赋值
        }
    },
    mutations:{
        OpenLogin(state){
            state.isShowLogin = true
        },
        CloseLogin(state){
            state.isShowLogin = false
        },
        OpenRegister(state){
            state.isShowRegister = true;
        },
        CloseRegister(state){
            state.isShowRegister = false;
        },
        SettingNickName(state,NickName){
            localStorage.setItem("NickName","")
            state.NickName = NickName
        }
    }
 })
 export default store;